import React from 'react'
import AboutMain from '../components/about/Main'
import Why from '../components/about/Why'
import StayContactCtn from '../components/about/StayContact'

function About() {
    return (
        <div>
            <AboutMain />
            <Why />
            <StayContactCtn />
        </div>
    )
}

export default About