import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="max-w-7xl mx-auto px-4 py-8 mx-6  pt-28">
            <div className="border px-6 py-12 md:px-20" >
                <h1 className="text-3xl font-bold mb-6 text-center font-serif font-medium">Terms and Conditions</h1>

                <h2 className="text-2xl xcxc mt-4 mb-2 font-lmk">1. Introduction</h2>
                <p className="mb-4 font-light leading-[24px] text-gray-600 mb-8 text-[20px]">
                    Welcome to the MIPORIS website. By accessing or using our website,
                    you agree to comply with and be bound by these Terms and Conditions.
                    If you disagree with any part of these terms, please do not use our
                    website.
                </p>

                <h2 className="text-2xl xcxc mt-4 mb-2 font-lmk">2. Definitions</h2>
                <ul className="list-disc list-inside mb-4 font-light leading-[24px] text-gray-600 mb-8 text-[20px]">
                    <li>“MIPORIS,” “we,” or “us” refers to MIPORIS, the owner of the website.</li>
                    <li>“You” or “User” refers to the individual accessing or using our website.</li>
                </ul>

                <h2 className="text-2xl xcxc mt-4 mb-2 font-lmk">3. Use of the Website</h2>
                <p className="mb-4 font-light leading-[24px] text-gray-600 mb-8 text-[20px]">
                    You agree to use the MIPORIS website only for lawful purposes and in a
                    manner that does not infringe the rights or restrict the use and enjoyment
                    of the website by any third party.
                </p>

                <h2 className="text-2xl xcxc mt-4 mb-2 font-lmk">4. Intellectual Property Rights</h2>
                <p className="mb-4 font-light leading-[24px] text-gray-600 mb-8 text-[20px]">
                    All content, logos, trademarks, and data on this website are the property
                    of MIPORIS and are protected by copyright and intellectual property
                    laws. You may not reproduce, distribute, or create derivative works of
                    this content without our explicit written permission.
                </p>

                <h2 className="text-2xl xcxc mt-4 mb-2 font-lmk">5. Limitation of Liability</h2>
                <p className="mb-4 font-light leading-[24px] text-gray-600 mb-8 text-[20px]">
                    MIPORIS is not liable for any damages arising from the use or inability to
                    use the website, including any errors, omissions, interruptions, or delays.
                    Your use of this website is at your own risk.
                </p>

                <h2 className="text-2xl xcxc mt-4 mb-2 font-lmk">6. Links to Other Websites</h2>
                <p className="mb-4 font-light leading-[24px] text-gray-600 mb-8 text-[20px]">
                    Our website may contain links to third-party websites that are not
                    operated by us. MIPORIS has no control over and assumes no
                    responsibility for the content or practices of any third-party sites.
                </p>

                <h2 className="text-2xl xcxc mt-4 mb-2 font-lmk">7. Modification of Terms</h2>
                <p className="mb-4 font-light leading-[24px] text-gray-600 mb-8 text-[20px]">
                    MIPORIS reserves the right to modify these Terms and Conditions at any
                    time. Your continued use of the website after any changes will constitute
                    your acceptance of the new terms.
                </p>

                <h2 className="text-2xl xcxc mt-4 mb-2 font-lmk">8. Governing Law</h2>
                <p className="mb-4 font-light leading-[24px] text-gray-600 mb-8 text-[20px]">
                    These Terms and Conditions are governed by and construed in
                    accordance with the laws of the United Kingdom, and you irrevocably
                    submit to the exclusive jurisdiction of the courts in that State or location.
                </p>

            </div>
        </div>
    );
};

export default TermsAndConditions;
