import React from 'react'
import Related from '../components/blog/related';
import StayContact from '../components/blog/subscribe';

function Blog2() {
    return (
        <div className='pt-24'>
            <Related />
            <StayContact />
        </div>
    )
}

export default Blog2;