import PricingPlans from "../components/pricing/PricingMain"
import { useSelector } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = await loadStripe('pk_test_51MUR8mSCCWTqFklHaTX94ON4adLv3YmkGtIolaj6QKb2Pbfc1cYoeVGG9Te7wZvVUqrBu45qqkINfTXHlWyOK9e600kByafUVu');

function Payment() {
    const { isAuthenticated } = useSelector((state) => state.auth);

    return (
        <div>
            <Elements stripe={stripePromise}>
                <PricingPlans isAuthenticated={isAuthenticated} />
            </Elements>
        </div>
    )
}

export default Payment