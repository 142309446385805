import FAQ from "../components/pricing/FAQ"
import ComparisonTable from "../components/pricing/PriceComparison"
import PricingPlans from "../components/pricing/PricingMain"
import TryMiporis from "../components/pricing/TryMiporis"
import { useSelector } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = await loadStripe('pk_test_51MUR8mSCCWTqFklHaTX94ON4adLv3YmkGtIolaj6QKb2Pbfc1cYoeVGG9Te7wZvVUqrBu45qqkINfTXHlWyOK9e600kByafUVu');

function Pricing() {
    const { isAuthenticated } = useSelector((state) => state.auth);

    return (
        <div>
            <Elements stripe={stripePromise}>
                <PricingPlans isAuthenticated={isAuthenticated} />
            </Elements>

            <ComparisonTable />
            {!isAuthenticated &&
                <>
                    <TryMiporis />
                    <FAQ />
                </>}

        </div>
    )
}

export default Pricing